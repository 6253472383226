function setupStickys() {
	const stickyEls = document.querySelectorAll("[data-intercept-toggle]");

	stickyEls.forEach(stickyEl => {
		const watcher = document.createElement("div");
		watcher.id = stickyEl.id ? `${stickyEl.id}_watcher` : '';
		watcher.setAttribute("data-intercept-watcher", "");

		stickyEl.before(watcher);

		const scrollObserver = new IntersectionObserver((entries) => {
			console.debug("scrollObserver has found", entries);
			stickyEl.classList.toggle(stickyEl.dataset.interceptToggle, !entries[0]?.isIntersecting);
		}, {
			rootMargin: stickyEl.dataset.interceptRootMargin,
		});

		scrollObserver.observe(watcher);
	});
}

setupStickys();
